<template>
  <div>
    <b-row>
      <b-col md="3">
        <b-form-input v-model="search" type="search" placeholder="Cari"></b-form-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table striped hover :items="datas" :fields="fields" :filter="search" :per-page="perPage"
          :current-page="currentPage">
          <template v-slot:cell(category)="data">
            <div>{{ data.item.category.name }}</div>
          </template>
          <template v-slot:cell(actions)="data">
            <b-button variant="danger" @click="deleteItem(data.item.id)">Hapus</b-button>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters, mapActions } from "vuex";
import axiosIns from "../../../libs/axios";

export default {

  data() {
    return {
      fields: [
        { key: 'id', label: 'Id' },
        { key: 'title', label: 'Title' },
        { key: 'category', label: 'Category' },
        { key: 'actions', label: 'Aksi' }
      ],
      search: '',
      loading: true,
      dialogDelete: false,
      perPage:10,
      currentPage:1,
      datas: [],
      length: 0,
      
    }
  },
  computed: {
    rows() {
      return this.length
    }
  },
  created() {
    axiosIns
      .get(`/api/post`)
      .then((res) => {
        if (res.data.meta.status == 'success') {
          this.loading = false;
          this.datas = res.data.data.posts.data;
          this.length = this.datas.length;
        }
        
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    deleteItem(id) {
      this.$swal({
        title: 'Kamu Yakin?',
        text: "Tindakan ini akan menghapus secara permanent!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Iya, Lanjutkan!'
      }).then((result) => {
        if (result.value) {
          axiosIns
            .delete(`/api/post/`+id)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Berhasil Menghapus blog`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `data berhasil dihapus`,
                },
              });
              const index = this.datas.findIndex((x) => x.id === id);
              if (index !== -1) {
                this.datas.splice(index, 1); // delete the post
              } else {
                console.log("Item with id " + id + " not found.");
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
    }
  }
};
</script>

<style lang="scss">
</style>
